<template>
  <div id="identify-profile">
    <div class="identify-profile">
      <card-header ref="header" :node="headerTitle" />
      <div id="content-scroll" class="content-scroll">
        <template v-if="auth.identity_status == 1">
          <img
            src="@/assets/image/indentify/wait_confirm.svg"
            alt=""
            class="confirm-image"
          />
          <p class="wait-confirm-text">申請中</p>
          <div class="image__div">
            <img
              :src="auth.identity_image_url"
              alt="confirm"
              class="image-indentify mb-3"
            />
          </div>
          <!-- <div class="image__div">
            <button class="btn-indentify-info" @click="redirectIndentityInfo">
              個人情報
            </button>
          </div> -->
        </template>
        <template v-else-if="auth.identity_status == 3">
          <img
            src="@/assets/image/indentify/confirm.svg"
            alt=""
            class="confirm-image"
          />
          <p class="wait-confirm-text confirm">確認済み</p>
          <div class="image__div">
            <img
              :src="auth.identity_image_url"
              alt="confirm"
              class="image-indentify mb-3"
            />
          </div>
          <!-- <div class="image__div">
            <button class="btn-indentify-info" @click="redirectIndentityInfo">
              個人情報
            </button>
          </div> -->
        </template>
        <template v-else>
          <div class="identify-profile-step-one">
            <p class="f-w6 identify-profile-step-one__title">
              本人確認書類を一つご提出ください
            </p>
            <p class="f-w3 identify-profile-step-one__note">
              本人確認書類は15分～24時間以内に完了します。
            </p>
            <b-form-radio-group
              class="
                identify-profile-step-one__papers
                d-flex
                justify-content-around
                flex-wrap
              "
            >
              <div class="license">
                <img
                  src="@/assets/image/driver'slicense.png"
                  class="license__image"
                />
                <div class="f-w6 license__radio text-center">
                  <b-form-radio>運転免許証</b-form-radio>
                </div>
              </div>
              <div class="passport">
                <div class="passport__image">
                  <img
                    src="@/assets/image/Passport.png"
                    class="passport__image"
                  />
                </div>
                <div class="f-w6 passport__radio">
                  <b-form-radio>パスポート</b-form-radio>
                </div>
              </div>
              <div class="health-insurance-card">
                <img
                  src="@/assets/image/Healthinsurancecard.png"
                  class="health-insurance-card__image"
                />
                <div class="f-w6 health-insurance-card__radio">
                  <b-form-radio>健康保険証</b-form-radio>
                </div>
              </div>
            </b-form-radio-group>
            <div class="image-not-approved">
              <p class="f-w6 text__red">以下のような画像は承認されません。</p>
              <div class="d-flex justify-content-between">
                <div>
                  <img src="@/assets/image/driver'slicense.png" />
                  <div
                    class="
                      f-w6
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                  >
                    <img
                      class="close-error"
                      src="@/assets/image/closeidentify.svg"
                    />
                    <span class="f-w3">情報の一部が隠れている</span>
                  </div>
                </div>
                <div>
                  <img src="@/assets/image/Healthinsurancecard.png" />
                  <div
                    class="
                      f-w6
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                  >
                    <img
                      class="close-error"
                      src="@/assets/image/closeidentify.svg"
                    />
                    <span class="f-w3">登録した情報と異なる</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="f-w3 other-papers text-left">
              <span
                >パスポート、マイナンバーカード等、顔写真付きのものでしたら可能です。社員証、学生証はご遠慮ください。</span
              >
            </div>
            <button
              type="submit"
              class="f-w3 mb-3"
              @click="identifyVerification()"
            >
              次へ
            </button>
          </div>
        </template>
      </div>
      <CardFooter ref="footer" />
    </div>
  </div>
</template>

<script>
import CardHeader from "@/components/CardHeader.vue";
import CardFooter from "@/components/CardFooter.vue";
import { mapGetters } from "vuex";

export default {
  name: "IdentifyProfile",
  components: {
    "card-header": CardHeader,
    CardFooter
  },
  metaInfo() {
    return {
      title: "身分証明書の登録",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      headerTitle: {
        text: "身分証明書の登録",
        isShowTitle: true,
        isShowLogo: false
      },
      backMessageDetail: false
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/user"
    })
  },
  methods: {
    redirectIndentityInfo() {
      this.$root.$refs.loading.start();
      if (this.auth.sex == 1) {
        this.$router.push({ name: "MenMyPagePersonalInformationEdit" });
      } else {
        this.$router.push({ name: "CastMyPagePersonalInformationEdit" });
      }
      this.$root.$refs.loading.finish();
    },
    identifyVerification() {
      let genderRouter = "CastMyPage";
      if (this.auth.sex === 1) {
        genderRouter = "MenMyPage";
      }
      let routerName = genderRouter + "IdentifyProfileStepTwo";
      if (this.backMessageDetail) {
        localStorage.setItem("back_user_" + this.auth.user_id, 1);
      }
      this.$router.push({ name: routerName });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === "MessageDetail") {
        vm.backMessageDetail = true;
      }
    });
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/_fontFamily.scss";
@import "~@/assets/scss/cast/identifyProfileStepOne.scss";
</style>
<style lang="scss" scoped>
.content-scroll {
  padding-top: 40px;
  padding-bottom: 50px;
  overflow-x: hidden;
  min-height: 100vh;
}

@media screen and (min-width: 1200px) {
  // .content-scroll {

  // }
}
</style>
